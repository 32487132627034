<template>
  <el-container style="height: 100%">
    <el-container id="left-container">
      <el-main style="overflow: hidden">
        <div id="layout-1" v-if="layoutType === 1">
          <div class="video-screen" @mouseenter="enterScreen(0)" @mouseleave="leaveScreen(0)">
            <LivePlayer
              v-if="!playerFlag[0]"
              class="live-player"
              ref="player0"
              :alt="playAlt"
              @play="playerPlay"
              @pause="playerPause"
              @ended="playEnd(0)"
              @error="playError"
              hide-big-play-button
              v-loading="loadingList[0]"
              element-loading-text="加载中..."
              element-loading-background="#000"
              aspect="fullscreen"
              :videoUrl="videoUrlList[0]"
              video-title=""
              fluent
              autoplay
              stretch
            >
              <div class="first-inner-div" v-show="upShowFlag[0]">
                <div class="top-div">
                  <div class="div-left">
                    <span>{{ nameList[0] }}</span>
                  </div>
                  <button class="div-right" @click="closeVideo(0)">关闭视频</button>
                </div>
              </div>
            </LivePlayer>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-aside width="200px">
      <el-tabs type="border-card" stretch style="height: 100%">
        <el-tab-pane label="设备" style="height: 100%">
          <el-input v-model="keyword" placeholder="输入设备或通道名称筛选" clearable style="margin-bottom: 10px; width: 199px"></el-input>
          <div id="aside-tree" class="aside-tree">
            <el-tree
              :data="deviceList"
              :props="treeProps"
              node-key="id"
              ref="deviceTree"
              :filter-node-method="deviceFilter"
              :default-expand-all="true"
              :highlight-current="true"
              :expand-on-click-node="false"
            >
              <span slot-scope="{ node, data }">
                <div @click="deviceClick(data)" style="display: flex; align-items: center">
                  <span>
                    <img v-if="data.type === 2" src="@/assets/img/channel.png" height="25px" width="25px" style="margin-right: 4px" />
                    <img v-else src="@/assets/img/device.png" height="25px" width="25px" style="margin-right: 4px" />
                  </span>
                  <span>{{ node.label }}</span>
                </div>
              </span>
            </el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-aside>
  </el-container>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer'
import { debounce } from '@/utils'
import { changeFlvUrl } from '@/utils/changeUrl'

export default {
  name: 'Preview',
  components: {
    LivePlayer
  },
  data() {
    return {
      layoutType: 1,
      currentNo: 0,
      upShowFlag: [],
      videoUrlList: [],
      needConnectUrl: true, // 重新连接校验用
      loadingList: [],
      keyword: '',
      onlineRadio: -1,
      deviceList: [],
      deviceListBak: [],
      treeProps: {
        label: 'displayName',
        children: 'children'
      },
      currentDeviceId: '',
      currentChannelId: '',
      idsList: [], // 播放的设备id和通道id
      nameList: [],
      physicIdList: [],
      timeCount: 0,
      tiemCountId: '',
      setFlag: false,
      findChannelFlag: 0,
      debounceFn: debounce(this.getDeviceTree, 200), // 防抖
      playAlt: '无信号',
      playerFlag: [],
      pn: '',
      sn: ''
    }
  },
  mounted() {
    this.getDeviceTree()
    this.changeLayout(1)
  },
  watch: {
    keyword(val) {
      // this.debounceFn()
      this.$refs.deviceTree.filter(val)
    },
    idsList(val) {
      const channelIds = []
      val.forEach((item) => {
        channelIds.push(item[1])
      })
      this.findChannel(channelIds, this.deviceList)
      this.deviceList = JSON.parse(JSON.stringify(this.deviceList))
    },
    findChannelFlag() {
      const channelIds = []
      this.idsList.forEach((item) => {
        channelIds.push(item[1])
      })
      this.findChannel(channelIds, this.deviceList)
      this.deviceList = JSON.parse(JSON.stringify(this.deviceList))
      if (this.keyword) {
        this.$nextTick(() => {
          this.$refs.deviceTree.filter(this.keyword)
        })
      }
    }
  },
  methods: {
    playError() {
      this.playAlt = this.nameList[0] + '拉流失败'
      this.$set(this.videoUrlList, 0, '')
    },
    // 播放停止
    playEnd(num) {
      this.$set(this.videoUrlList, num, '')
      this.$set(this.loadingList, num, true)

      setTimeout(() => {
        if (this.needConnectUrl && !this.videoUrlList[num]) {
          this.getUrl(this.pn, this.sn)
        }
      }, 1000 * 15)
    },
    // 设备筛选
    deviceFilter(val, data) {
      if (!val) return true
      return data.displayName.indexOf(val) !== -1
    },
    // 播放
    playerPlay() {
      if (!this.setFlag) {
        clearInterval(this.tiemCountId)
        for (let i = 0; i < this.videoUrlList.length; i++) {
          if (this.videoUrlList[i] && this.videoUrlList[i] !== '') {
            const refName = 'player' + i
            if (this.$refs[refName]) {
              this.$refs[refName].setCurrentTime(this.timeCount)
            }
          }
        }
        this.setFlag = true
      }
    },
    // 暂停
    playerPause(val) {
      this.setFlag = false
      this.timeCount = val
      this.tiemCountId = setInterval(this.timeAdd, 1000)
    },
    // 计时
    timeAdd() {
      this.timeCount++
    },
    // 递归查找播放的通道
    findChannel(channelIds, list) {
      for (let j = 0; j < list.length; j++) {
        if (channelIds.indexOf(list[j].id) !== -1) {
          list[j].playFlag = true
        } else {
          list[j].playFlag = false
          this.findChannel(channelIds, list[j].children || [])
        }
      }
    },
    // 获取设备树
    getDeviceTree() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/devices/tree'),
        method: 'get',
        params: this.$http.adornParams()
      }).then((res) => {
        if (res.code === 0) {
          this.deviceList = res.data
          this.deviceListBak = JSON.parse(JSON.stringify(this.deviceList))
          this.findChannelFlag++
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 设备单击
    deviceClick(data) {
      const deviceId = data.deviceId
      const channelId = data.id
      const name = data.displayName
      const physicId = data.physicId
      this.registerProtocol = data.registerProtocol
      this.currentDeviceId = deviceId
      this.currentChannelId = channelId
      this.pn = data.tag.pn
      this.sn = data.tag.sn
      this.$set(this.idsList, this.currentNo, [this.currentDeviceId, this.currentChannelId])
      this.$set(this.nameList, this.currentNo, name)
      this.$set(this.physicIdList, this.currentNo, physicId)
      this.getUrl(this.pn, this.sn)
    },
    // 获取播放地址
    getUrl(pn, sn) {
      const num = this.currentNo
      this.$set(this.loadingList, num, true)
      this.$set(this.videoUrlList, num, '')
      this.$set(this.playerFlag, num, true)

      this.$http({
        url: this.$http.adornUrl('/proxy/httpsPort'),
        method: 'get',
        params: this.$http.adornParams()
      })
        .then((res) => {
          if (res) {
            const flv = changeFlvUrl(`:${res ? res : '7867'}/${pn}_${sn}/${pn}_${sn}_01.flv`)
            this.$set(this.videoUrlList, num, flv)
          } else {
            this.$set(this.videoUrlList, num, '')
            this.playAlt = this.nameList[num] + '拉流失败'
          }
          setTimeout(() => {
            this.$set(this.playerFlag, num, false)
          }, 10)
          this.$set(this.loadingList, num, false)
        })
        .catch((err) => {
          this.$set(this.loadingList, num, false)
          this.playAlt = this.nameList[num] + '拉流失败'
          setTimeout(() => {
            this.$set(this.playerFlag, num, false)
          }, 10)
          console.log('err => ', err)
        })
    },
    // 关闭视频
    closeVideo(num) {
      this.playAlt = '无信号'
      this.$set(this.videoUrlList, num, '')
      this.needConnectUrl = false
      this.$set(this.idsList, num, '')
      this.$set(this.nameList, num, '')
      this.$set(this.physicIdList, num, '')
    },
    // 屏幕移入
    enterScreen(num) {
      this.$set(this.upShowFlag, num, true)
    },
    // 屏幕移出
    leaveScreen(num) {
      this.$set(this.upShowFlag, num, false)
    },
    // 改变布局
    changeLayout(type) {
      if (this.layoutType === type) {
        return
      }

      this.altList = ''
      this.layoutType = type
      this.currentNo = 0
      this.deviceList = this.deviceListBak
    },
    // 在线状态筛选
    changeOnline() {
      this.getDeviceTree()
    }
  }
}
</script>

<style lang="scss" scoped>
// tabs
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
.el-tabs--border-card {
  border: none;
}
::v-deep .el-tabs__item:hover {
  cursor: default;
}
::v-deep .el-tabs__content {
  height: calc(100% - 39px);
}
#left-container {
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
.el-main {
  padding: 0;
}
#layout-1 {
  height: 100%;
  .video-screen {
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }
}
.el-footer {
  padding: 0 0 0 20px;
  margin-right: 20px;
}
.live-player {
  height: 100%;
  .first-inner-div {
    height: 20px;
    width: 100%;
    position: absolute;
    .img-canvas {
      position: absolute;
      z-index: 10;
    }
    .top-div {
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: space-between;
      background-color: rgba(30, 36, 44, 0.6);
      .div-left {
        padding: 10px;
        span:nth-of-type(1) {
          padding-right: 5px;
        }
        span:nth-of-type(2) {
          cursor: pointer;
        }
      }
      .div-right {
        padding: 10px;
        cursor: pointer;
      }
    }
    .left-aside {
      width: 5%;
      position: absolute;
      background-color: rgba(30, 36, 44, 0.3);
      top: 26%;
      right: 0;
      .button-img {
        cursor: pointer;
      }
    }
  }
}
.el-aside {
  overflow: visible;
  margin-left: 20px;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
.el-radio {
  margin-right: 14px;
}
// 树
.aside-tree {
  height: calc(100% - 4.8rem);
  overflow: auto;
}
</style>
