import { findStr } from '@/utils'
import Vue from 'vue'

/**
 * 映射flv外网地址
 * @param {*} url 源地址
 * @returns 外网地址
 */
export function changeFlvUrl(url) {
  if (!url) return ''
  let { protocol, hostname } = location

  if (hostname === 'temp.raythink-tech.com') {
    hostname = 'tempvideo.raythink-tech.com'
  }

  return protocol + '//' + hostname + url + '?' + Vue.cookie.get('token')
}

/**
 * 映射file外网地址
 * @param {*} url 源地址
 * @returns 外网地址
 */
export function changeFileUrl(url) {
  if (process.env.NODE_ENV !== 'production') return url
  if (!url) return ''
  // 判断是否同源
  if (url.indexOf(location.origin) === -1) {
    const aimIndex = findStr(url, '/', 2)
    if (aimIndex !== -1) {
      url = location.origin + url.substr(aimIndex)
    }
  }
  return url
}
